var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("UiKitModal", {
    ref: "modal",
    attrs: { "hide-on-body-click": false },
    on: { hide: _vm.onHideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v("\n    " + _vm._s(_vm.t("header")) + "\n  ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.hasLeaderBlockValue
              ? _c("FormEditLeader", {
                  model: {
                    value: _vm.$v.leaderBlock.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.leaderBlock, "$model", $$v)
                    },
                    expression: "$v.leaderBlock.$model",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-success",
                  attrs: {
                    disabled: _vm.isDisabledBtnSave,
                    label: _vm.t("btn_save"),
                  },
                  on: { click: _vm.onClickBtnSave },
                }),
                _vm._v(" "),
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-primary",
                  attrs: { label: _vm.t("btn_cancel") },
                  on: { click: _vm.onClickBtnCancel },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }