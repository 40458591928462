var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "UiKitModal2",
    {
      attrs: {
        name: _vm.name,
        "click-to-close": false,
        "min-width": 600,
        "min-height": 400,
        width: "80%",
        height: "90%",
      },
    },
    [
      _c("div", { staticClass: "h-100 w-100 p-4 d-flex flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center mb-4",
          },
          [
            _c("h1", { staticClass: "modal-title" }, [
              _vm._v("Редактирование"),
            ]),
            _vm._v(" "),
            _c(
              "UiKitButton",
              {
                staticClass: "btn-lg btn-secondary",
                on: { click: _vm.onClickBtnCloseModal },
              },
              [_c("i", { staticClass: "fa fa-times" })]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-1" },
          [
            _c(
              "ContentWithPreloader",
              { attrs: { "is-loading": _vm.isLoading } },
              [
                _c("ImageEditor", {
                  attrs: { image: _vm.file, "original-url": _vm.originalUrl },
                  on: { "save-edited-image": _vm.onSaveEditedImage },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }