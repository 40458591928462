var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "uiKit-wysiwyg" },
    [
      _vm.isEditorAttachedToDom
        ? _c(
            "Editor",
            _vm._g(
              _vm._b(
                {
                  ref: "editor",
                  attrs: { "api-key": _vm.apiKey, init: _vm.settings },
                  on: {
                    onBeforeExecCommand: _vm.onEditorBeforeExecCommand,
                    onExecCommand: _vm.onEditorExecCommand,
                    onInit: _vm.onInit,
                  },
                  model: {
                    value: _vm.content,
                    callback: function ($$v) {
                      _vm.content = $$v
                    },
                    expression: "content",
                  },
                },
                "Editor",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }