var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex align-items-center mt-2 mb-4" }, [
    _c(
      "div",
      { staticClass: "w-100 d-flex flex-column" },
      [
        _c(
          "UiKitFormField",
          {
            staticClass: "mb-4",
            attrs: { label: _vm.t("name.label"), required: "" },
          },
          [
            _c("UiKitInput", {
              attrs: { placeholder: _vm.t("name.placeholder") },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _vm._v(" "),
            _vm.v.programBlock.name.$error
              ? _c("UiKitFormFieldError", {
                  attrs: { text: _vm.requiredErrorMessage },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          { attrs: { label: _vm.t("description.label"), required: "" } },
          [
            _c("UiKitWysiwyg", {
              attrs: { placeholder: _vm.t("description.placeholder") },
              model: {
                value: _vm.description,
                callback: function ($$v) {
                  _vm.description = $$v
                },
                expression: "description",
              },
            }),
            _vm._v(" "),
            _vm.v.programBlock.description.$error
              ? _c("UiKitFormFieldError", {
                  attrs: { text: _vm.requiredErrorMessage },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }