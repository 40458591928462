<template>
  <div>
    <UiKitFormField class="mb-4" :label="t('label')">
      <UiKitSelect
        v-model="products"
        :placeholder="t('placeholder')"
        :options="options.productList"
        multiple
        searchable
        :get-option-label="customLabel"
        :close-on-select="false"
      />
    </UiKitFormField>
  </div>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitSelect from '@admin/ui/admin/UiKitSelect.vue'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'

const MAX_PRODUCTS_COUNT = 2

export default defineComponent({
  name: 'ProductContentItemSimilarProducts',

  components: {
    UiKitFormField,
    UiKitSelect,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    ...mapState('admin/productManagement/product', [
      'options',
    ]),

    products: {
      get() {
        return this.localItem.products
      },

      set(value) {
        if (value.length <= MAX_PRODUCTS_COUNT) {
          this.localItem.products = value

          this.emitChange()
        }
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = this.item
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    customLabel({ id, name }) {
      return `id: ${id} - ${name}`
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.similar_products.${name}`,
      )
    },
  },
})
</script>
