import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnosticsOffer = new (class extends ApiBase {
  /**
   * Получения оферты для школы
   *
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/school/${schoolId}/template/diagnostics/offer`

    return this._GET(url)
  }

  /**
   * Загрузка оферты
   *
   * @param {number} schoolId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, data) {
    const url = `/school/${schoolId}/template/diagnostics/offer/upload`

    return this._POST(url, data)
  }
})()
