var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UiKitSelect", {
    attrs: {
      dir: _vm.dir,
      clearable: _vm.allowEmpty,
      "close-on-select": _vm.closeOnSelect,
      disabled: _vm.disabled,
      filterable: _vm.internalSearch,
      "get-option-key": _vm.getOptionKey,
      "get-option-label": _vm.getOptionLabel,
      "input-id": _vm.inputId,
      label: _vm.name,
      loading: _vm.loading,
      "max-height": _vm.maxHeightInPx,
      multiple: _vm.multiple,
      "no-drop": _vm.isNoDrop,
      "no-options-txt": _vm.noOptions,
      options: _vm.optionsFiltered,
      placeholder: _vm.placeholder,
      searchable: _vm.searchable,
      selectable: _vm.selectable,
      taggable: _vm.taggable,
      value: _vm.value,
    },
    on: {
      close: _vm.onClose,
      input: _vm.onInput,
      search: _vm.onSearch,
      "option:deselected": _vm.onOptionDeselect,
      "option:selected": _vm.onOptionSelect,
      "option:created": _vm.onOptionCreate,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }