import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSendingTypeOptions = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/trigger/option/types')
  }
})()
