import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductOptionsOnlineLessons =
  new (class extends ApiBase {
    /**
     * Список онлайн уроков продукта
     *
     * @param {number} productId
     * @returns {Promise}
     */
    get(productId) {
      return this._GET(`/product/${productId}/options/online-lessons`)
    }
  })()
