var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "UiKitModal2",
    { attrs: { name: _vm.name, "click-to-close": false, width: "70%" } },
    [
      _c("div", { staticClass: "p-4" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c(
              "UiKitButton",
              {
                staticClass: "btn-secondary btn-lg ml-4",
                on: { click: _vm.onClickBtnCloseModal },
              },
              [_c("i", { staticClass: "fa fa-times" })]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "ContentWithPreloader",
              { attrs: { "is-loading": _vm.isLoading } },
              [
                _c("img", {
                  class: _vm.$style.image,
                  attrs: { src: _vm.originalUrl, alt: _vm.fileName },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }