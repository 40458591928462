var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uiKit-formField" },
    [
      _vm.label
        ? _c(
            "div",
            { staticClass: "d-flex mb-2" },
            [
              _vm.label
                ? _c("span", { staticClass: "uiKit-formField-label" }, [
                    _vm._v("\n      " + _vm._s(_vm.label) + "\n      "),
                    _vm.required
                      ? _c("span", { staticClass: "text-pink" }, [_vm._v("*")])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showEraser
                ? _c("i", {
                    staticClass:
                      "fas fa-eraser ml-2 uiKit-formField-eraser-icon",
                    on: { click: _vm.erase },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hint
                ? _c("i", {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { placement: "right", arrow: true },
                        expression: "{ placement: 'right', arrow: true }",
                      },
                    ],
                    staticClass: "form-hint fas fa-question-circle ml-2",
                    attrs: { content: _vm.hint },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("label-additional"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "uiKit-formField-value", class: { error: _vm.errors } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "UiKitFormFieldError",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error",
            },
          ],
        },
        [_vm._v(_vm._s(_vm.error))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }