<template>
  <div class="mb-4">
    <UiKitFormField :label="t('documents.label')" class="mb-2">
      <FileUploadMany v-model="documents" class="mb-4" @error="onErrorUpload" />
    </UiKitFormField>

    <UiKitFormField :label="t('text.label')">
      <UiKitInput
        v-model="documentsText"
        :placeholder="t('text.placeholder')"
      />
    </UiKitFormField>
  </div>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitInput from '@admin/ui/admin/UiKitInput.vue'
import FileUploadMany from 'CommonComponents/FileUploadMany.vue'
import { NotifyMixin } from 'Helpers/mixins/NotifyMixin'
import { cloneDeep } from 'lodash'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemDocuments',

  components: {
    FileUploadMany,
    UiKitFormField,
    UiKitInput,
  },

  mixins: [
    NotifyMixin,
  ],

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    documents: {
      get() {
        return this.localItem.documents
      },

      set(value) {
        this.localItem.documents = value

        this.emitChange()
      },
    },

    documentsText: {
      get() {
        return this.localItem.documentsText
      },

      set(value) {
        this.localItem.documentsText = value

        this.emitChange()
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onErrorUpload(message) {
      this.notifyMethods().showError(`${this.t('load_file')}: ${message}`)
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.documents.${name}`)
    },
  },
})
</script>
