<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHideModal">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditLeader
        v-if="hasLeaderBlockValue"
        v-model="$v.leaderBlock.$model"
      />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :disabled="isDisabledBtnSave"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditLeader from '@admin/components/admin/productManagement/product/content/items/leaders/FormEditLeader.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitModal from '@admin/ui/admin/UiKitModal.vue'
import { isNull } from 'lodash'
import { required } from 'vuelidate/lib/validators'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalEditLeader',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditLeader,
  },

  data() {
    return {
      leaderBlock: null,
    }
  },

  validations() {
    return {
      leaderBlock: {
        name: {
          required,
        },

        position: {
          required,
        },
      },
    }
  },

  computed: {
    hasLeaderBlockValue() {
      return !isNull(this.leaderBlock)
    },

    isDisabledBtnSave() {
      return this.$v.$error
    },
  },

  methods: {
    onClickBtnSave() {
      this.$emit('change', this.leaderBlock)
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.leaderBlock = value
      this.$refs.modal.show()
    },

    hide() {
      this.emitHide()
      this.$refs.modal.hide()
    },

    onHideModal() {
      this.emitHide()
    },

    emitHide() {
      this.$emit('hide')
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.leaders.modal_edit.${name}`,
      )
    },
  },
})
</script>
