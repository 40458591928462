import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementFetchLinkAccess = new (class extends ApiBase {
  /**
   * Получение ссылки для доступа к продукту
   *
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/product/${id}/link-access`)
  }
})()
