var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex align-items-center mt-2 mb-4" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "UiKitFormField",
            { staticClass: "mb-4", attrs: { label: _vm.t("photo.label") } },
            [
              _c("UiKitUploadAny", {
                model: {
                  value: _vm.photo,
                  callback: function ($$v) {
                    _vm.photo = $$v
                  },
                  expression: "photo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "UiKitFormField",
            { staticClass: "mb-4", attrs: { label: _vm.t("rate.label") } },
            [
              _c("RatingStars", {
                model: {
                  value: _vm.rate,
                  callback: function ($$v) {
                    _vm.rate = $$v
                  },
                  expression: "rate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c(
            "UiKitFormField",
            {
              staticClass: "mb-4",
              attrs: { label: _vm.t("author_name.label"), required: "" },
            },
            [
              _c("UiKitInput", {
                attrs: { placeholder: _vm.t("author_name.placeholder") },
                model: {
                  value: _vm.authorName,
                  callback: function ($$v) {
                    _vm.authorName = $$v
                  },
                  expression: "authorName",
                },
              }),
              _vm._v(" "),
              _vm.v.review.authorName.$error
                ? _c("UiKitFormFieldError", {
                    attrs: { text: _vm.requiredErrorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "UiKitFormField",
            { staticClass: "mb-4", attrs: { label: _vm.t("subtitle.label") } },
            [
              _c("UiKitInput", {
                attrs: { placeholder: _vm.t("subtitle.placeholder") },
                model: {
                  value: _vm.subtitle,
                  callback: function ($$v) {
                    _vm.subtitle = $$v
                  },
                  expression: "subtitle",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "UiKitFormField",
            {
              staticClass: "mb-4",
              attrs: { label: _vm.t("text.label"), required: "" },
            },
            [
              _c("UiKitWysiwyg", {
                attrs: { placeholder: _vm.t("text.placeholder") },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
              _vm._v(" "),
              _vm.v.review.text.$error
                ? _c("UiKitFormFieldError", {
                    attrs: { text: _vm.requiredErrorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "UiKitFormField",
            { attrs: { label: _vm.t("date.label"), required: "" } },
            [
              _c("UiKitDatePicker", {
                attrs: { placeholder: _vm.t("date.placeholder") },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
              _vm._v(" "),
              _vm.v.review.date.$error
                ? _c("UiKitFormFieldError", {
                    attrs: { text: _vm.requiredErrorMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }