import { ApiModulatorActiveModules } from 'ApiRest/Api/Modulator/ActiveModules/List'
import ApiPermissions from 'ApiRest/Api/User/Permissions'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  // TODO: перенести это из модуля навигации
  async fetchProfile({ commit, dispatch }) {
    const { data } = await ApiPermissions.get()

    commit('setProfile', data)

    // TODO: перенести в отдельный action
    await dispatch('setLocale')
    commit('setTextsLoaded', true)
  },

  async setLocale({ commit, dispatch }) {
    dispatch('admin/locale/setLangDefault', null, {
      root: true,
    })

    commit('admin/locale/setUserType', 'manager', {
      root: true,
    })

    commit('admin/locale/setInstitutionType', 'school', {
      root: true,
    })

    await dispatch('admin/locale/changeLocale', null, {
      root: true,
    })
  },

  async fetchActiveModules({ commit }) {
    const { data } = await ApiModulatorActiveModules.get()

    commit('setActiveModules', data)
  },
}
