<template>
  <div class="uiKit-wysiwyg">
    <Editor
      v-if="isEditorAttachedToDom"
      ref="editor"
      v-model="content"
      :api-key="apiKey"
      :init="settings"
      v-bind="$attrs"
      @onBeforeExecCommand="onEditorBeforeExecCommand"
      @onExecCommand="onEditorExecCommand"
      @onInit="onInit"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import ApiUpload from 'Api/Upload'
import { getCookie } from 'Helpers/cookie'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'UiKitWysiwyg',

  components: {
    Editor,
  },

  inheritAttrs: false,

  model: {
    prop: 'text',
    event: 'change',
  },

  props: {
    placeholder: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    minHeight: {
      type: Number,
      default: 300,
    },

    plugins: {
      type: Array,
      default: () => [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help hr',
      ],
    },

    toolbar: {
      type: String,
      default: `undo redo | formatselect | bold italic backcolor underline subscript superscript |
                alignleft aligncenter alignright alignjustify |
                bullist numlist outdent indent hr link | image media code table | removeformat | help`,
    },

    toolbarMode: {
      type: String,
      default: 'sliding',
    },

    imagesUploadHandler: {
      type: Function,
      /**
       * @param {Object} blobInfo
       * @param {Function} success
       * @returns {Promise}
       */
      async default(blobInfo, success) {
        const { data } = await ApiUpload.sendImage(blobInfo.blob())

        const imageUrl = data.url

        success(imageUrl)
      },
    },

    setup: {
      type: Function,
      default() {},
    },

    keepStyles: {
      type: Boolean,
      default: true,
    },

    theme: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      apiKey: process.iblsEnv.IBLS_API_KEY_TINYMCE,
      isEditorAttachedToDom: true, // Нужен для смены темы
    }
  },

  computed: {
    ...mapGetters('admin/theme', [
      'isDarkTheme',
    ]),

    cssTheme() {
      if (this.theme) {
        return {
          contentCss: this.theme,
          skin: this.theme === 'default' ? 'oxide' : 'oxide-dark',
        }
      }

      return {
        contentCss: this.isDarkTheme ? 'dark' : 'default',
        skin: this.isDarkTheme ? 'oxide-dark' : 'oxide',
      }
    },

    settings() {
      return {
        branding: false,
        content_css: this.cssTheme.contentCss,
        content_style: this.contentStyle,
        elementpath: false,
        image_dimensions: false,
        images_upload_handler: this.imagesUploadHandler,
        keep_styles: this.keepStyles,
        language: this.language,
        menubar: false,
        min_height: this.minHeight,
        placeholder: this.placeholder,
        plugins: this.plugins,
        setup: this.setup,
        skin: this.cssTheme.skin,
        toolbar: this.toolbar,
        toolbar_mode: this.toolbarMode,
      }
    },

    content: {
      get() {
        return this.text
      },

      set(value) {
        this.$emit('change', value)
      },
    },

    /**
     * @returns {string}
     */
    contentStyle() {
      return `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                color: rgba(118, 118, 118, 1);
                opacity: 1;
              }`
    },

    /**
     * @returns {string}
     */
    language() {
      return getCookie('lang') || 'ru'
    },

    /**
     * @returns {Object|null}
     * @public
     */
    api() {
      return this.$refs.editor?.editor ?? null
    },
  },

  watch: {
    async isDarkTheme() {
      await this.setIsEditorAttachedToDom()
    },
  },

  methods: {
    async setIsEditorAttachedToDom() {
      this.isEditorAttachedToDom = false

      await this.$nextTick()

      this.isEditorAttachedToDom = true
    },

    /**
     * @param {Object} payload
     */
    onEditorBeforeExecCommand(payload) {
      this.$emit('before-command', payload)
    },

    /**
     * @param {Object} payload
     */
    onEditorExecCommand(payload) {
      this.$emit('command', payload)
    },

    /**
     * @param {Object} payload
     */
    onInit(payload) {
      this.$emit('init', payload)
    },
  },
})
</script>

<style lang="scss">
@import '~@admin/scss/bootstrap/variables';
@import '~bootstrap/scss/forms';

.uiKit-wysiwyg {
  .tox-tinymce {
    width: 100%;
    border-width: 0;
    border-radius: 5px;
  }
}
</style>
