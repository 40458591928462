var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Uploader", {
    attrs: { image: _vm.localImage },
    on: {
      error: function ($event) {
        return _vm.emitError($event)
      },
      "upload-image": function ($event) {
        return _vm.processUpload($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ data, actions, events }) {
          return [
            _c(
              "div",
              _vm._g(
                {
                  staticClass: "uiKit-upload-small-image",
                  class: { "uiKit-upload-small-image-hover": data.dragover },
                  on: {
                    drop: function ($event) {
                      return actions.processUploadImage($event)
                    },
                  },
                },
                events.drag
              ),
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !data.isImageUploaded,
                        expression: "!data.isImageUploaded",
                      },
                    ],
                    staticClass: "uiKit-upload-small-image-dropzone",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "uiKit-upload-small-image-preview" },
                      [_c("span", { staticClass: "fas fa-file-image" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !data.progress,
                            expression: "!data.progress",
                          },
                        ],
                        staticClass: "uiKit-upload-small-image-filename",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("ui.select_file")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: data.progress,
                            expression: "data.progress",
                          },
                        ],
                        staticClass:
                          "progress w-100 h-100 rounded-0 bg-transparent",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar bg-secondary",
                            style: {
                              width: `${data.progress}%`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.progress) +
                                "%\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        on: {
                          mouseleave: function ($event) {
                            actions.drag().leave()
                          },
                          mouseover: function ($event) {
                            actions.drag().over()
                          },
                        },
                      },
                      [
                        _c("input", {
                          ref: "upload",
                          attrs: { disabled: _vm.readonly, type: "file" },
                          on: {
                            change: function ($event) {
                              return actions.processUploadImage($event)
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: data.isImageUploaded,
                        expression: "data.isImageUploaded",
                      },
                    ],
                    staticClass: "uiKit-upload-small-image-contents h-100",
                    on: {
                      mouseleave: function ($event) {
                        actions.drag().leave()
                      },
                      mouseover: function ($event) {
                        actions.drag().over()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "uiKit-upload-small-image-preview" },
                      [
                        _vm.preview
                          ? _c("VuePreviewer", {
                              attrs: {
                                images: [
                                  {
                                    src: data.imageUrl,
                                    thumbnailSrc: data.imageThumbUrl,
                                    width: "100%",
                                    height: "auto",
                                  },
                                ],
                                "show-footer": false,
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: data.imageThumbUrl || data.imageUrl,
                                alt: "image",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !data.progress,
                            expression: "!data.progress",
                          },
                        ],
                        staticClass: "uiKit-upload-small-image-filename",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return actions.processChange(_vm.$refs.upload)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(data.imageName || "Без названия") +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.readonly && _vm.canClear
                      ? _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !data.progress,
                                expression: "!data.progress",
                              },
                            ],
                            staticClass: "uiKit-upload-small-image-clear",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return actions.processClearImage()
                              },
                            },
                          },
                          [_c("span", { staticClass: "fas fa-times" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: data.progress,
                            expression: "data.progress",
                          },
                        ],
                        staticClass:
                          "progress w-100 h-100 rounded-0 bg-transparent",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar bg-secondary",
                            style: {
                              width: `${data.progress}%`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.progress) +
                                "%\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }