// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlockEdit__block__DBZB {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 5px 10px 10px;\n  margin-bottom: 20px;\n  background: var(--app-gray-700);\n}", "",{"version":3,"sources":["webpack://./webpack/@admin/components/admin/productManagement/product/common/BlockEdit.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,+BAAA;AAAF","sourcesContent":["\n.block {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 5px 10px 10px;\n  margin-bottom: 20px;\n  background: var(--app-gray-700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": "BlockEdit__block__DBZB"
};
export default ___CSS_LOADER_EXPORT___;
