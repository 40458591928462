var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Uploader", {
    attrs: {
      file: _vm.localFile,
      "is-video": _vm.isVideo,
      "max-file-size-mb": _vm.maxFileSizeMb,
    },
    on: {
      error: _vm.emitError,
      "upload-file": _vm.processUpload,
      "clear-file": _vm.processClear,
      "edit-image": _vm.processEdit,
      "view-image": _vm.processView,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ data, actions, events }) {
          return [
            _c(
              "div",
              _vm._g(
                {
                  staticClass: "uiKit-upload-small-any",
                  class: _vm.cssClass(data),
                  on: {
                    drop: (e) => actions.processUploadAny(e, _vm.isPrivate),
                  },
                },
                events.drag
              ),
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !data.isFileUploaded,
                        expression: "!data.isFileUploaded",
                      },
                    ],
                    staticClass: "uiKit-upload-small-any-dropzone",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "uiKit-upload-small-any-preview" },
                      [_c("span", { staticClass: "fa fa-upload" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !data.progress,
                            expression: "!data.progress",
                          },
                        ],
                        staticClass: "uiKit-upload-small-any-filename",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("ui.select_file")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: data.progress,
                            expression: "data.progress",
                          },
                        ],
                        staticClass:
                          "progress w-100 h-100 rounded-0 bg-transparent",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar bg-secondary",
                            style: { width: `${data.progress}%` },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.progress) +
                                "%\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        on: {
                          mouseleave: function ($event) {
                            actions.drag().leave()
                          },
                          mouseover: function ($event) {
                            actions.drag().over()
                          },
                        },
                      },
                      [
                        _c("input", {
                          ref: "upload",
                          attrs: {
                            accept: _vm.accept,
                            disabled: _vm.readonly,
                            type: "file",
                          },
                          on: {
                            change: (e) =>
                              actions.processUploadAny(e, _vm.isPrivate),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.file
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: data.isFileUploaded,
                            expression: "data.isFileUploaded",
                          },
                        ],
                        staticClass: "uiKit-upload-small-any-contents",
                        on: {
                          mouseleave: function ($event) {
                            actions.drag().leave()
                          },
                          mouseover: function ($event) {
                            actions.drag().over()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "uiKit-upload-small-any-preview" },
                          [
                            _vm.file.isImage && !_vm.file.mime.includes("heic")
                              ? [
                                  _vm.file.thumbUrl
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.file.thumbUrl,
                                          alt: "thumbnail",
                                        },
                                      })
                                    : _c("span", {
                                        staticClass: "fas fa-file-code",
                                      }),
                                ]
                              : [
                                  _vm.file.mime &&
                                  _vm.file.mime.includes("image")
                                    ? _c("span", {
                                        staticClass: "fas fa-file-image",
                                      })
                                    : _vm.file.mime &&
                                      (_vm.file.mime.includes("audio/mpeg") ||
                                        _vm.file.mime.includes("audio/mp4"))
                                    ? _c("span", {
                                        staticClass: "fas fa-file-video",
                                      })
                                    : _vm.file.mime &&
                                      _vm.file.mime.includes("word")
                                    ? _c("span", {
                                        staticClass: "fas fa-file-word",
                                      })
                                    : _vm.file.mime &&
                                      (_vm.file.mime.includes("excel") ||
                                        _vm.file.mime.includes("sheet"))
                                    ? _c("span", {
                                        staticClass: "fas fa-file-excel",
                                      })
                                    : _vm.file.mime &&
                                      _vm.file.mime.includes("pdf")
                                    ? _c("span", {
                                        staticClass: "fas fa-file-pdf",
                                      })
                                    : _vm.file.mime &&
                                      _vm.file.mime.includes("text/plain")
                                    ? _c("span", {
                                        staticClass: "fas fa-file-alt",
                                      })
                                    : _c("span", {
                                        staticClass: "fas fa-file",
                                      }),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !data.progress,
                                expression: "!data.progress",
                              },
                            ],
                            staticClass: "uiKit-upload-small-any-filename",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return actions.processChange(_vm.$refs.upload)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(data.fileName || "Без названия") +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        data.isImage
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !data.progress,
                                    expression: "!data.progress",
                                  },
                                ],
                                staticClass: "uiKit-upload-small-any-view",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.processView.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-eye" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        data.isImage
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !data.progress,
                                    expression: "!data.progress",
                                  },
                                ],
                                staticClass: "uiKit-upload-small-any-edit",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.processEdit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-pencil-alt" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        data.downloadUrl && _vm.canDownload
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !data.progress,
                                    expression: "!data.progress",
                                  },
                                ],
                                staticClass: "uiKit-upload-small-any-download",
                                attrs: { href: data.downloadUrl },
                              },
                              [_c("i", { staticClass: "fas fa-download" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.readonly && _vm.canClear
                          ? _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !data.progress,
                                    expression: "!data.progress",
                                  },
                                ],
                                staticClass: "uiKit-upload-small-any-clear",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return actions.processClearFile.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-times" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: data.progress,
                                expression: "data.progress",
                              },
                            ],
                            staticClass:
                              "progress w-100 h-100 rounded-0 bg-transparent",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "progress-bar bg-secondary",
                                style: { width: `${data.progress}%` },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(data.progress) +
                                    "%\n          "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("ModalEditFileUpload", {
                  ref: "modalEditFileUpload",
                  attrs: {
                    file: _vm.localFile,
                    "original-url": _vm.originalUrl,
                  },
                  on: { "upload-file": _vm.processUpload },
                }),
                _vm._v(" "),
                _c("ModalViewImage", {
                  ref: "modalViewImage",
                  attrs: {
                    file: _vm.localFile,
                    "original-url": _vm.originalUrl,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }