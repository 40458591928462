var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("UiKitModal", {
    ref: "modal",
    attrs: { "hide-on-body-click": false },
    on: { hide: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.t("header")) + " ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "UiKitFormField",
                  { staticClass: "mb-4", attrs: { label: _vm.t("columns") } },
                  [
                    _c("UiKitSelect", {
                      attrs: { options: _vm.options.columns, clearable: false },
                      model: {
                        value: _vm.model.columns,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "columns", $$v)
                        },
                        expression: "model.columns",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-success",
                  attrs: { label: _vm.t("btn_add") },
                  on: { click: _vm.onClickBtnAdd },
                }),
                _vm._v(" "),
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-primary",
                  attrs: { label: _vm.t("btn_cancel") },
                  on: { click: _vm.onClickBtnCancel },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }