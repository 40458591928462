import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionSubscribers = new (class extends ApiBase {
  /**
   * Получаем список подписчиков
   *
   * @param {number} subscriptionId
   * @returns {Promise}
   */
  get(subscriptionId) {
    return this._GET(`/subscriptions/${subscriptionId}/subscribers`)
  }
})()
