import { render, staticRenderFns } from "./ProductContentItemLeaders.vue?vue&type=template&id=293fd7b2"
import script from "./ProductContentItemLeaders.vue?vue&type=script&lang=js"
export * from "./ProductContentItemLeaders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-gamma-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('293fd7b2')) {
      api.createRecord('293fd7b2', component.options)
    } else {
      api.reload('293fd7b2', component.options)
    }
    module.hot.accept("./ProductContentItemLeaders.vue?vue&type=template&id=293fd7b2", function () {
      api.rerender('293fd7b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@admin/components/admin/productManagement/product/content/items/ProductContentItemLeaders.vue"
export default component.exports