var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-many" },
    [
      _vm.isRendered.uploader
        ? _c("Uploader", {
            attrs: {
              "is-document": _vm.isDocument,
              "mime-allowed": _vm.acceptMime,
              "max-file-size-mb": _vm.maxFileSizeMb,
            },
            on: { error: _vm.emitError, "upload-file": _vm.processUpload },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ data, actions, events }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "upload-many-dropzone btn btn-lg",
                            class: {
                              "btn-outline-primary": data.dragover,
                              "btn-primary": !data.dragover,
                            },
                            on: { drop: (e) => _vm.onChange(actions, e) },
                          },
                          events.drag
                        ),
                        [
                          _c("i", { staticClass: "fas fa-upload mr-2" }),
                          _vm._v(" "),
                          _c("label", [
                            _c("input", {
                              ref: "upload",
                              attrs: { accept: _vm.acceptFormat, type: "file" },
                              on: {
                                change: (e) => _vm.onChange(actions, e),
                                drop: (e) => _vm.onChange(actions, e),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.t("select_file")) +
                                "\n        "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              721507424
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasFiles,
              expression: "hasFiles",
            },
          ],
          staticClass: "list-unstyled mb-0 mt-4",
        },
        _vm._l(_vm.localFiles, function (file) {
          return _c(
            "li",
            {
              key: file.id,
              staticClass: "d-flex flex-wrap align-items-center mb-2",
            },
            [
              _vm.isShowingImages
                ? [
                    file.isImage
                      ? [
                          _c("div", { staticClass: "position-relative" }, [
                            _c("img", {
                              staticClass: "upload-many__img mb-2",
                              attrs: {
                                alt: file.name,
                                src: _vm.getDownloadUrl(file),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { class: _vm.$style.imageActions },
                              _vm._l(_vm.imageActions, function (btn, i) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      { name: "tippy", rawName: "v-tippy" },
                                    ],
                                    key: i,
                                    staticClass: "btn btn-secondary",
                                    class: _vm.$style.imageAction,
                                    attrs: { content: btn.text },
                                    on: {
                                      click: function ($event) {
                                        return btn.onClick(file)
                                      },
                                    },
                                  },
                                  [_c("i", { class: btn.icon })]
                                )
                              }),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ModalEditFileUpload", {
                            ref: "modalEditFileUpload",
                            refInFor: true,
                            attrs: {
                              file: _vm.selectFile,
                              "original-url": _vm.originalUrl,
                            },
                            on: { "upload-file": _vm.onAddCommentAttachment },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-100" }),
                        ]
                      : file.mime.includes("image")
                      ? _c("i", {
                          staticClass:
                            "fas fa-file-image upload-many__img-icon mr-2",
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "btn cursor-default px-0",
                  class: _vm.downloadBtnClass,
                },
                [
                  _c("span", { staticClass: "px-3 p-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(file.name || _vm.t("untitled")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isRendered.btnDownload
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "p-2 cursor-pointer text-secondary border-left",
                          on: {
                            click: function ($event) {
                              return _vm.onClickBtnDownload(file)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-download fa-fw" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRendered.btnRemove
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "p-2 cursor-pointer text-secondary border-left",
                          on: {
                            click: function ($event) {
                              return _vm.onClickBtnRemove(file)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-times fa-fw" })]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("ModalConfirmRemoveFileUploadMany", { ref: "modalConfirmRemoveFile" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }