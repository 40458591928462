<template>
  <modal
    :class="$style.modal"
    v-bind="$attrs"
    :transition="transition"
    :shift-y="shiftY"
    :height="height"
    v-on="$listeners"
  >
    <slot></slot>
  </modal>
</template>

<script>
export default {
  name: 'UiKitModal2',

  inheritAttrs: false,

  props: {
    height: {
      type: [String, Number],
      default: 'auto',
    },

    transition: {
      type: String,
      default: '',
    },

    shiftY: {
      type: Number,
      default: 0.3,
    },
  },
}
</script>

<style module lang="scss">
.modal {
  [class='vm--modal'] {
    overflow: visible;
    color: var(--app-modal-content-color);
    background-color: var(--app-black) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2),
      0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2),
      0 16px 16px rgba(0, 0, 0, 0.2);
  }
}
</style>
