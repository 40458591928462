<template>
  <UiKitModal ref="modal" :hide-on-body-click="false">
    <template #header>{{ t('header') }}</template>

    <template #body>
      <FormEditLeader v-model="$v.leaderBlock.$model" />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_add')"
          :disabled="isDisabledBtnAdd"
          @click="onClickBtnAdd"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditLeader from '@admin/components/admin/productManagement/product/content/items/leaders/FormEditLeader.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitModal from '@admin/ui/admin/UiKitModal.vue'
import { required } from 'vuelidate/lib/validators'

import { defineComponent } from 'vue'

const createLeaderBLock = () => ({
  alt: '',
  id: null,
  name: '',
  photo: null,
  position: '',
  text: '',
})

export default defineComponent({
  name: 'ModalAddLeader',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditLeader,
  },

  data() {
    return {
      leaderBlock: createLeaderBLock(),
    }
  },

  computed: {
    isDisabledBtnAdd() {
      return this.$v.$error
    },
  },

  validations() {
    return {
      leaderBlock: {
        name: {
          required,
        },

        position: {
          required,
        },
      },
    }
  },

  methods: {
    onClickBtnAdd() {
      this.$emit('add', this.leaderBlock)

      this.leaderBlock = createLeaderBLock()
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    hide() {
      this.$refs.modal.hide()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.leaders.modal_add.${name}`,
      )
    },
  },
})
</script>
