var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "UiKitFormField",
        { staticClass: "mb-4", attrs: { label: _vm.t("label") } },
        [
          _c("UiKitSelect", {
            attrs: {
              "hide-selected": true,
              multiple: true,
              options: _vm.options.leaderOptionList,
            },
            model: {
              value: _vm.leaders,
              callback: function ($$v) {
                _vm.leaders = $$v
              },
              expression: "leaders",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "UiKitButton",
        { staticClass: "btn-success mb-4", on: { click: _vm.onClickBtnAdd } },
        [
          _c("i", { staticClass: "fa fa-plus" }),
          _vm._v("\n    " + _vm._s(_vm.t("btn_add")) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "DragAndDropSortContainer",
        {
          attrs: { "lock-axis": "y", "use-drag-handle": true },
          model: {
            value: _vm.leaders,
            callback: function ($$v) {
              _vm.leaders = $$v
            },
            expression: "leaders",
          },
        },
        _vm._l(_vm.leaders, function (leader, index) {
          return _c("LeadersItem", {
            key: index,
            attrs: { index: index, leader: leader },
            on: {
              edit: () => _vm.onEdit(leader, index),
              remove: () => _vm.onRemove(index),
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("ModalAddLeader", {
        ref: "modalAddLeader",
        on: { add: _vm.onAddLeader },
      }),
      _vm._v(" "),
      _c("ModalEditLeader", {
        ref: "modalEditLeader",
        on: {
          change: _vm.onChangeLeader,
          hide: _vm.onHideModalEditLeaderBlock,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }