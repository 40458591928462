import { userActions } from '@admin/accessControl/userActions'
import { getActiveModule } from 'Helpers/accessControl/module'

/**
 * Онлайн уроки
 *
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setAbilityByModuleOnlineLesson = ({
  activeModules,
  helpers,
  permission,
  routes,
}) => {
  /**
   * @type {{
   *   settings: {
   *     'conference-type': Object
   *   }
   * }|undefined}
   */
  const moduleOnlineLesson = getActiveModule(activeModules, 'online-lesson')

  if (moduleOnlineLesson !== undefined) {
    const routeNames = routes.get('online-lesson')

    if (permission === 'menu_online_lessons') {
      helpers.allowReadMenuItemForRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons') {
      helpers.allowReadRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons_part_update') {
      helpers.allowReadRoutes(routeNames)
      helpers.allowUpdateRoutes(routeNames)

      return true
    }

    if (permission === 'online_lessons_view') {
      helpers.allowReadRoutes([
        'admin-the-online-lesson-preview',
      ])

      return true
    }

    if (permission === 'showcase_online_lessons_view') {
      helpers.allowReadRoutes([
        'admin-showcase-online-lessons',
        'admin-the-online-lesson-preview',
      ])
      helpers.allowReadMenuItemForRoutes([
        'admin-showcase-online-lessons',
      ])
      helpers.allowActionOnRoutes(
        userActions.RUN_BIG_BLUE_BUTTON_SHOWCASE_LESSON,
        [
          'admin-showcase-the-online-lesson',
        ],
      )
    }

    if (permission === 'online_lessons_full_update') {
      helpers.allowCrudRoutes(routeNames)

      helpers.allowActionOnRoutes(userActions.FULL_UPDATE_ONLINE_LESSONS, [
        'admin-showcase-online-lessons',
      ])

      return true
    }

    if (permission === 'online_lessons_video_update') {
      helpers.allowCrudRoutes(routeNames)

      helpers.allowActionOnRoutes(userActions.VIDEO_UPDATE_ONLINE_LESSONS, [
        'admin-the-online-lesson',
      ])

      return true
    }
  }

  return false
}
