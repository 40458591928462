<template>
  <div class="d-flex align-items-center mt-2 mb-4">
    <div class="w-100 d-flex flex-column">
      <UiKitFormField class="mb-4" :label="t('avatar.label')">
        <UiKitUploadSmallImage
          v-model="photo"
          @error="processError(`${t('avatar.error_message')}: ${$event}`)"
        />
      </UiKitFormField>

      <UiKitFormField class="mb-3" :label="t('alt_avatar.label')">
        <UiKitInput v-model="alt" :placeholder="t('alt_avatar.placeholder')" />
      </UiKitFormField>

      <UiKitFormField class="mb-4" :label="t('full_name.label')" required>
        <UiKitInput v-model="name" :placeholder="t('full_name.placeholder')" />
      </UiKitFormField>

      <UiKitFormField class="mb-4" :label="t('position.label')" required>
        <UiKitInput
          v-model="position"
          :placeholder="t('position.placeholder')"
        />
      </UiKitFormField>

      <UiKitFormField :label="t('topic.label')">
        <UiKitInput v-model="text" :placeholder="t('topic.placeholder')" />
      </UiKitFormField>
    </div>
  </div>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitInput from '@admin/ui/admin/UiKitInput.vue'
import UiKitUploadSmallImage from '@admin/ui/admin/UiKitUploadSmallImage.vue'
import { NotifyMixin } from 'Helpers/mixins/NotifyMixin'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormEditLeader',

  components: {
    UiKitFormField,
    UiKitInput,
    UiKitUploadSmallImage,
  },

  mixins: [
    NotifyMixin,
  ],

  model: {
    prop: 'leaderBlock',
    event: 'input',
  },

  props: {
    leaderBlock: {
      type: Object,
      required: true,
    },
  },

  computed: {
    photo: {
      get() {
        return this.leaderBlock.photo
      },

      set(value) {
        this.$emit('input', {
          ...this.leaderBlock,
          photo: value,
        })
      },
    },

    name: {
      get() {
        return this.leaderBlock.name
      },

      set(value) {
        this.$emit('input', {
          ...this.leaderBlock,
          name: value,
        })
      },
    },

    position: {
      get() {
        return this.leaderBlock.position
      },

      set(value) {
        this.$emit('input', {
          ...this.leaderBlock,
          position: value,
        })
      },
    },

    text: {
      get() {
        return this.leaderBlock.text
      },

      set(value) {
        this.$emit('input', {
          ...this.leaderBlock,
          text: value,
        })
      },
    },

    alt: {
      get() {
        return this.leaderBlock.alt
      },

      set(value) {
        this.$emit('input', {
          ...this.leaderBlock,
          alt: value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} message
     */
    processError(message) {
      this.notifyMethods().showError(message)
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.leaders.form.${name}`,
      )
    },
  },
})
</script>
