import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSendingSendManual = new (class extends ApiBase {
  /**
   * Отправка рассылки вручную
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(parameters = {}) {
    return this._POST('/manual-trigger-sending', parameters)
  }
})()
