import { ApiProductPromocodeGroupList } from 'ApiRest/Api/ProductPromocodeGroup/List'
import { ApiTriggerSending } from 'ApiRest/Api/TriggerSending'
import { ApiTriggerSendingList } from 'ApiRest/Api/TriggerSending/List'
import { ApiTriggerSendingStatusOptions } from 'ApiRest/Api/TriggerSending/Options/Statuses'
import { ApiTriggerSendingTypeOptions } from 'ApiRest/Api/TriggerSending/Options/Types'
import { ApiTriggerSendingSendManual } from 'ApiRest/Api/TriggerSending/SendManual'
import Constants from 'Constants'

export default {
  async fetchTriggerSendings({ commit }, filters = {}) {
    const { data, headers } = await ApiTriggerSendingList.get(filters)

    commit('setFilters', filters)
    commit('setTriggerSendings', data)
    commit('setPagination', headers)
  },

  async fetchTriggerSending({ commit }, triggerSendingId) {
    const { data } = await ApiTriggerSending.get(triggerSendingId)

    commit('setTriggerSending', data)
  },

  async createTriggerSending({ commit, state }) {
    const { data } = await ApiTriggerSending.post(state.triggerSending)

    commit('setTriggerSending', data)
  },

  async updateTriggerSending({ commit, state }) {
    const { data } = await ApiTriggerSending.put(
      state.triggerSending.id,
      state.triggerSending,
    )

    commit('setTriggerSending', data)
  },

  async sendTriggerSendingManual({ state }, clients) {
    const payload = {
      triggerSending: {
        id: state.triggerSending.id,
        name: state.triggerSending.name,
      },

      users: clients,
    }

    await ApiTriggerSendingSendManual.post(payload)
  },

  async fetchTriggerTypes({ commit }) {
    const { data } = await ApiTriggerSendingTypeOptions.get()

    commit('setOptionsList', {
      path: 'triggerTypes',
      value: data,
    })
  },

  async fetchTriggerStatuses({ commit }) {
    const { data } = await ApiTriggerSendingStatusOptions.get()

    commit('setOptionsList', {
      path: 'triggerStatuses',
      value: data,
    })

    const statusInactive = data.find(
      (status) => status.id === Constants.triggerSendingStatuses.INACTIVE,
    )

    commit('setTriggerSendingValue', {
      path: 'status',
      value: statusInactive,
    })
  },

  async fetchPromocodeGroups({ commit }) {
    const { data } = await ApiProductPromocodeGroupList.get()

    commit('setOptionsList', {
      path: 'promocodeGroups',
      value: data,
    })
  },

  async fetchTriggerSendingOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchTriggerTypes'),
      dispatch('fetchTriggerStatuses'),
      dispatch('fetchPromocodeGroups'),
    ])
  },
}
