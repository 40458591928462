import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'

export const ModalProgramMixin = Vue.extend({
  validations() {
    return {
      programBlock: {
        name: {
          required,
        },

        description: {
          required,
        },
      },
    }
  },

  methods: {
    onClickBtnCancel() {
      this.hide()
    },

    onInput({ key, value }) {
      this.programBlock[key] = value
      this.$v.programBlock[key].$touch()
    },
  },
})
