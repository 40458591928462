var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("BlockEdit", [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c(
        "div",
        {
          directives: [{ name: "handle", rawName: "v-handle" }],
          staticClass: "p-3 handle cursor-move",
        },
        [_c("i", { staticClass: "fas fa-bars" })]
      ),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.leader.name))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c(
          "UiKitButton",
          {
            staticClass: "square",
            on: {
              click: function ($event) {
                return _vm.$emit("edit")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-pen" })]
        ),
        _vm._v(" "),
        _c(
          "UiKitButton",
          {
            staticClass: "square",
            on: {
              click: function ($event) {
                return _vm.$emit("remove")
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }