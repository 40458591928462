<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditReview
        v-if="hasReview"
        :v="$v"
        :review="review"
        @input="onInput"
      />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditReview from '@admin/components/admin/productManagement/product/content/items/reviews/FormEditReview.vue'
import { ModalReviewMixin } from '@admin/mixins/productManagement/ModalReviewMixin'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitModal from '@admin/ui/admin/UiKitModal.vue'
import { cloneDeep, isNull } from 'lodash'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalEditProgram',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditReview,
  },

  mixins: [
    ModalReviewMixin,
  ],

  data() {
    return {
      review: null,
    }
  },

  computed: {
    hasReview() {
      return !isNull(this.review)
    },
  },

  methods: {
    onClickBtnSave() {
      this.$v.review.$touch()

      if (this.$v.review.$error) {
        return false
      }

      this.$emit('change', this.review)
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.review = cloneDeep(value)
      this.$refs.modal.show()
    },

    hide() {
      this.emitHide()
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
      this.emitHide()
    },

    emitHide() {
      this.$emit('hide')
    },

    resetData() {
      this.$v.review.$reset()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.modal_edit.${name}`,
      )
    },
  },
})
</script>
