var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    _vm._g(
      _vm._b(
        {
          class: _vm.$style.modal,
          attrs: {
            transition: _vm.transition,
            "shift-y": _vm.shiftY,
            height: _vm.height,
          },
        },
        "modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }