import { ApiCourseManagementAnalyticsList } from 'ApiRest/Api/CourseManagement/Products/Analytics/AnalyticsList'

export default {
  async fetchAnalytics({ commit }, filters = {}) {
    const { data, headers } = await ApiCourseManagementAnalyticsList.get(
      filters,
    )

    commit('setFilters', filters)
    commit('setAnalytics', data)
    commit('setPagination', headers)
  },
}
