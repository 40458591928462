var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "UiKitFormField",
        { attrs: { label: _vm.t("label") } },
        [
          _c(
            "DragAndDropSortContainer",
            {
              attrs: { "lock-axis": "y", "use-drag-handle": true },
              model: {
                value: _vm.reviews,
                callback: function ($$v) {
                  _vm.reviews = $$v
                },
                expression: "reviews",
              },
            },
            _vm._l(_vm.reviews, function (review, index) {
              return _c("ReviewItem", {
                key: index,
                attrs: { index: index, item: review },
                on: {
                  edit: () => _vm.onEdit(review, index),
                  remove: () => _vm.onRemove(index),
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "UiKitButton",
        { staticClass: "btn-success", on: { click: _vm.onClickBtnAdd } },
        [
          _c("i", { staticClass: "fa fa-plus" }),
          _vm._v("\n    " + _vm._s(_vm.t("btn_add")) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c("ModalAddReview", {
        ref: "modalAddReview",
        on: { add: _vm.onAddReview },
      }),
      _vm._v(" "),
      _c("ModalEditReview", {
        ref: "modalEditReview",
        on: { change: _vm.onChangeReview, hide: _vm.onHideModalEditReview },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }