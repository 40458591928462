var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "UiKitModal2",
    {
      attrs: { name: _vm.name, "click-to-close": false },
      on: { "before-open": _vm.onBeforeOpen },
    },
    [
      _c("div", { staticClass: "p-4" }, [
        _c("h5", { staticClass: "modal-title mb-4" }, [_vm._v("Внимание")]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "mb-4" }, [
            _vm._v("\n        Вы действительно хотите удалить файл "),
            _c("b", [_vm._v(_vm._s(_vm.fileName))]),
            _vm._v("?\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-lg-end justify-content-between",
            },
            [
              _c("UiKitButton", {
                staticClass: "btn-danger btn-lg",
                attrs: { label: "Да, удалить" },
                on: { click: _vm.onClickBtnRemoveFile },
              }),
              _vm._v(" "),
              _c("UiKitButton", {
                staticClass: "btn-secondary btn-lg ml-4",
                attrs: { label: "Отмена" },
                on: { click: _vm.onClickBtnCloseModal },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }