var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    { class: _vm.labelCssClass },
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            class: _vm.inputCssClass,
            attrs: { disabled: _vm.disabled, type: "checkbox" },
            domProps: {
              value: _vm.value,
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, _vm.value) > -1
                : _vm.model,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.model = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.model = $$c
                }
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c("span", { class: _vm.$style.toggle }),
      _vm._v(" "),
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("span", { class: _vm.$style.text }, [
                _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
              ])
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.hint
        ? _c("i", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { placement: "right", arrow: true },
                expression: "{ placement: 'right', arrow: true }",
              },
            ],
            staticClass: "form-hint ml-2 fas fa-question-circle",
            attrs: { content: _vm.hint },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }