import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'

export const ModalReviewMixin = Vue.extend({
  validations() {
    return {
      review: {
        authorName: {
          required,
        },

        text: {
          required,
        },

        date: {
          required,
        },

        rate: {
          required,
        },
      },
    }
  },

  methods: {
    onClickBtnCancel() {
      this.hide()
    },

    onInput({ key, value }) {
      this.review[key] = value

      if (this.$v.review[key]) {
        this.$v.review[key].$touch()
      }
    },
  },
})
