<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditReview :v="$v" :review="review" @input="onInput" />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_add')"
          @click="onClickBtnAdd"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditReview from '@admin/components/admin/productManagement/product/content/items/reviews/FormEditReview.vue'
import { ModalReviewMixin } from '@admin/mixins/productManagement/ModalReviewMixin'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitModal from '@admin/ui/admin/UiKitModal.vue'

import { defineComponent } from 'vue'

const createReview = () => ({
  photo: null,
  rate: 0,
  authorName: '',
  subtitle: '',
  text: '',
  date: null,
})

export default defineComponent({
  name: 'ModalAddReview',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditReview,
  },

  mixins: [
    ModalReviewMixin,
  ],

  data() {
    return {
      review: createReview(),
    }
  },

  methods: {
    onClickBtnAdd() {
      this.$v.review.$touch()

      if (this.$v.review.$error) {
        return false
      }

      this.$emit('add', this.review)

      this.review = createReview()
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.review = createReview()
      this.$v.review.$reset()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.modal_add.${name}`,
      )
    },
  },
})
</script>
