// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiKitModal2__modal__9RoN [class=vm--modal] {\n  background-color: var(--app-black) !important;\n}\n.UiKitModal2__modal__9RoN [class=vm--modal] {\n  overflow: visible;\n  color: var(--app-modal-content-color);\n  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2), 0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2), 0 16px 16px rgba(0, 0, 0, 0.2);\n}", "",{"version":3,"sources":["webpack://./webpack/@admin/ui/admin/UiKitModal2.vue"],"names":[],"mappings":"AAEE;EAGE,6CAAA;AAAJ;AAHE;EACE,iBAAA;EACA,qCAAA;EAEA,kKAAA;AADJ","sourcesContent":["\n.modal {\n  [class='vm--modal'] {\n    overflow: visible;\n    color: var(--app-modal-content-color);\n    background-color: var(--app-black) !important;\n    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2),\n      0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2),\n      0 16px 16px rgba(0, 0, 0, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "UiKitModal2__modal__9RoN"
};
export default ___CSS_LOADER_EXPORT___;
