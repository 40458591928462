var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex align-items-center mb-4" }, [
    _c(
      "div",
      { staticClass: "w-100" },
      [
        _c(
          "UiKitFormField",
          { staticClass: "mb-2", attrs: { label: _vm.t("icon") } },
          [
            _c("UiKitUploadSmallImage", {
              model: {
                value: _vm.icon,
                callback: function ($$v) {
                  _vm.icon = $$v
                },
                expression: "icon",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          { attrs: { label: _vm.t("text") } },
          [
            _c("UiKitInput", {
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3 cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.$emit("remove")
          },
        },
      },
      [_c("i", { staticClass: "fas fa-times" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }