import invoice from './invoice'
import productPromocode from './productPromocode'
import promocode from './promocode'
import service from './service'
import subscription from './subscription'
import triggerSendings from './triggerSendings'

export default [
  ...invoice,
  ...productPromocode,
  ...promocode,
  ...service,
  ...subscription,
  ...triggerSendings,
]
