// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalViewImage__image__92DT {\n  width: 100%;\n  height: 90vh;\n  -o-object-fit: contain;\n     object-fit: contain;\n}", "",{"version":3,"sources":["webpack://./webpack/components/ModalViewImage.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;KAAA,mBAAA;AAAF","sourcesContent":["\n.image {\n  width: 100%;\n  height: 90vh;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "ModalViewImage__image__92DT"
};
export default ___CSS_LOADER_EXPORT___;
