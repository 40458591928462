var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex align-items-center no-wrap" },
    _vm._l(_vm.maxRate, function (rating) {
      return _c(
        "div",
        {
          key: rating,
          staticClass: "d-flex align-items-center justify-content-center",
          class: _vm.itemCssClass,
          on: { click: () => _vm.onChange(rating) },
        },
        [_c("img", { attrs: { src: _vm.getIcon(rating) } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }