<template>
  <div class="mb-4">
    <UiKitFormField :label="t('label')">
      <DragAndDropSortContainer
        v-model="gallery"
        lock-axis="y"
        :use-drag-handle="true"
      >
        <GalleryItem
          v-for="(galleryItem, index) in gallery"
          :key="index"
          :index="index"
          :item="galleryItem"
          @edit="() => onEdit(galleryItem, index)"
          @remove="() => onRemove(index)"
        />
      </DragAndDropSortContainer>
    </UiKitFormField>

    <UiKitButton class="btn-success" @click="onClickBtnAdd">
      <i class="fa fa-plus"></i>
      {{ t('btn_add') }}
    </UiKitButton>

    <ModalAddGalleryItem ref="modalAddGalleryItem" @add="onAddGalleryItem" />

    <ModalEditGalleryItem
      ref="modalEditGalleryItem"
      @change="onChangeGalleryItem"
      @hide="onHideModalEditGalleryItem"
    />
  </div>
</template>

<script>
import DragAndDropSortContainer from '@admin/components/admin/productManagement/product/content/DragAndDropSortContainer.vue'
import GalleryItem from '@admin/components/admin/productManagement/product/content/items/gallery/GalleryItem.vue'
import ModalAddGalleryItem from '@admin/components/admin/productManagement/product/content/items/gallery/ModalAddGalleryItem.vue'
import ModalEditGalleryItem from '@admin/components/admin/productManagement/product/content/items/gallery/ModalEditGalleryItem.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import { cloneDeep } from 'lodash'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemGallery',

  components: {
    DragAndDropSortContainer,
    GalleryItem,
    UiKitFormField,
    ModalEditGalleryItem,
    ModalAddGalleryItem,
    UiKitButton,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      galleryItemIndex: null,
      localItem: null,
    }
  },

  computed: {
    gallery: {
      get() {
        return this.localItem.gallery
      },

      set(value) {
        this.setGallery(value)
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onClickBtnAdd() {
      this.$refs.modalAddGalleryItem.show()
    },

    onEdit(galleryItem, index) {
      this.galleryItemIndex = index
      this.$refs.modalEditGalleryItem.show(galleryItem)
    },

    onRemove(index) {
      this.removeGalleryItem(index)
      this.emitChange()
    },

    onAddGalleryItem(galleryItem) {
      this.addGalleryItem(galleryItem)
      this.emitChange()
    },

    onChangeGalleryItem(galleryItem) {
      this.updateGalleryItem({
        index: this.galleryItemIndex,
        value: galleryItem,
      })
      this.emitChange()
    },

    onHideModalEditGalleryItem() {
      this.galleryItemIndex = null
    },

    removeGalleryItem(index) {
      this.localItem.gallery.splice(index, 1)
    },

    updateGalleryItem({ index, value }) {
      this.$set(this.localItem.gallery, index, value)
    },

    addGalleryItem(galleryItem) {
      this.localItem.gallery.push(galleryItem)
    },

    setGallery(value) {
      this.localItem.gallery = value
      this.emitChange()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.gallery.${name}`)
    },
  },
})
</script>
