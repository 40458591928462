var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "UiKitFormField",
        { staticClass: "mb-4", attrs: { label: _vm.t("label") } },
        [
          _c("UiKitSelect", {
            attrs: {
              "create-option": (name) => ({ name }),
              placeholder: _vm.t("placeholder"),
              multiple: "",
              "no-drop": "",
              taggable: "",
            },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }