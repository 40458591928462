var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("UiKitModal", {
    ref: "modal",
    attrs: { "hide-on-body-click": false },
    on: { hide: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v("\n    " + _vm._s(_vm.t("header")) + "\n  ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex align-items-center mt-2 mb-4" }, [
              _c(
                "div",
                { staticClass: "w-100 d-flex flex-column" },
                [
                  _c(
                    "UiKitFormField",
                    {
                      staticClass: "mb-4",
                      attrs: { label: _vm.t("image.label"), required: "" },
                    },
                    [
                      _c("UiKitUploadAny", {
                        model: {
                          value: _vm.item,
                          callback: function ($$v) {
                            _vm.item = $$v
                          },
                          expression: "item",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.item.$error
                        ? _c("UiKitFormFieldError", {
                            attrs: { text: _vm.requiredErrorMessage },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-success",
                  attrs: { label: _vm.t("btn_save") },
                  on: { click: _vm.onClickBtnSave },
                }),
                _vm._v(" "),
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-primary",
                  attrs: { label: _vm.t("btn_cancel") },
                  on: { click: _vm.onClickBtnCancel },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }