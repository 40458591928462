import { ApiTriggerSendingNotifications } from 'ApiRest/Api/TriggerSending/Notifications'

export default {
  async fetchNotifications({ rootState, commit }, filters = {}) {
    const { data, headers } = await ApiTriggerSendingNotifications.get(
      rootState.admin.triggerSending.triggerSending.id,
      filters,
    )

    commit('setFilters', filters)
    commit('setNotifications', data)
    commit('setPagination', headers)
  },
}
