var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "UiKitFormField",
        { attrs: { label: _vm.t("policy_file.label") } },
        [
          _c("UiKitUploadAny", {
            staticClass: "mb-4",
            on: { error: _vm.onErrorUpload },
            model: {
              value: _vm.policyFile,
              callback: function ($$v) {
                _vm.policyFile = $$v
              },
              expression: "policyFile",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }