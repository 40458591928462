var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "UiKitFormField",
        { staticClass: "mb-4", attrs: { label: _vm.t("label") } },
        [
          _c("UiKitSelect", {
            attrs: {
              placeholder: _vm.t("placeholder"),
              options: _vm.options.productList,
              multiple: "",
              searchable: "",
              "get-option-label": _vm.customLabel,
              "close-on-select": false,
            },
            model: {
              value: _vm.products,
              callback: function ($$v) {
                _vm.products = $$v
              },
              expression: "products",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }