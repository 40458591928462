var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "uiKit-modal modal",
      class: { show: !_vm.localHidden },
      on: {
        "!click": function ($event) {
          return _vm.processClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered", class: _vm.size },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._t("header")], 2),
              _vm._v(" "),
              _c("button", { staticClass: "close", on: { click: _vm.hide } }, [
                _vm._v("×"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
            _vm._v(" "),
            _vm.$slots.footer
              ? _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "fade modal-backdrop",
        class: { show: !_vm.localHidden },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }