var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("UiKitModal", {
    ref: "modal",
    attrs: { "hide-on-body-click": false },
    on: { hide: _vm.onHide },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.t("header")) + " ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("UiKitButton", {
                  staticClass: "btn-primary square",
                  attrs: { label: "+" },
                  on: { click: _vm.addIcon },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4" },
              _vm._l(_vm.icons, function (icon, iconIndex) {
                return _c("IconItem", {
                  key: iconIndex,
                  attrs: { item: icon },
                  on: {
                    change: ($event) => _vm.onChange(iconIndex, $event),
                    remove: () => _vm.onRemove(iconIndex),
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-success",
                  attrs: { label: _vm.t("btn_add") },
                  on: { click: _vm.onClickBtnAdd },
                }),
                _vm._v(" "),
                _c("UiKitButton", {
                  staticClass: "btn-lg btn-primary",
                  attrs: { label: _vm.t("btn_cancel") },
                  on: { click: _vm.onClickBtnCancel },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }