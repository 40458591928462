var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "UiKitFormField",
        { staticClass: "mb-2", attrs: { label: _vm.t("documents.label") } },
        [
          _c("FileUploadMany", {
            staticClass: "mb-4",
            on: { error: _vm.onErrorUpload },
            model: {
              value: _vm.documents,
              callback: function ($$v) {
                _vm.documents = $$v
              },
              expression: "documents",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "UiKitFormField",
        { attrs: { label: _vm.t("text.label") } },
        [
          _c("UiKitInput", {
            attrs: { placeholder: _vm.t("text.placeholder") },
            model: {
              value: _vm.documentsText,
              callback: function ($$v) {
                _vm.documentsText = $$v
              },
              expression: "documentsText",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }