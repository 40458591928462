import { render, staticRenderFns } from "./BlockEdit.vue?vue&type=template&id=8ba4760e"
import script from "./BlockEdit.vue?vue&type=script&lang=js"
export * from "./BlockEdit.vue?vue&type=script&lang=js"
import style0 from "./BlockEdit.vue?vue&type=style&index=0&id=8ba4760e&module=true&lang=scss"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./BlockEdit.vue?vue&type=style&index=0&id=8ba4760e&module=true&lang=scss"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("./BlockEdit.vue?vue&type=style&index=0&id=8ba4760e&module=true&lang=scss")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("/var/www/dev-gamma-frontend/node_modules/vue-hot-reload-api/dist/index.js").rerender("8ba4760e")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev-gamma-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ba4760e')) {
      api.createRecord('8ba4760e', component.options)
    } else {
      api.reload('8ba4760e', component.options)
    }
    module.hot.accept("./BlockEdit.vue?vue&type=template&id=8ba4760e", function () {
      api.rerender('8ba4760e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "webpack/@admin/components/admin/productManagement/product/common/BlockEdit.vue"
export default component.exports