<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <div class="d-flex align-items-center mt-2 mb-4">
        <div class="w-100 d-flex flex-column">
          <UiKitFormField class="mb-4" :label="t('image.label')" required>
            <UiKitUploadAny v-model="item" />

            <UiKitFormFieldError
              v-if="$v.item.$error"
              :text="requiredErrorMessage"
            />
          </UiKitFormField>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import UiKitFormField from '@admin/ui/admin/UiKitFormField.vue'
import UiKitFormFieldError from '@admin/ui/admin/UiKitFormFieldError.vue'
import UiKitUploadAny from '@admin/ui/admin/UiKitUploadAny.vue'
import UiKitButton from '@admin/ui/admin/UiKitButton.vue'
import UiKitModal from '@admin/ui/admin/UiKitModal.vue'
import { requiredErrorMessage } from 'Helpers/ValidationHelper'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep } from 'lodash'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalAddPartnersItem',

  components: {
    UiKitFormField,
    UiKitFormFieldError,
    UiKitUploadAny,
    UiKitModal,
    UiKitButton,
  },

  validations() {
    return {
      item: {
        image: {
          required,
        },
      },
    }
  },

  data() {
    return {
      item: null,
      requiredErrorMessage,
    }
  },

  methods: {
    onClickBtnSave() {
      this.$v.item.$touch()

      if (this.$v.item.$error) {
        return false
      }

      this.$emit('change', this.item)
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.item = cloneDeep(value)
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.item = null
      this.$v.item.$reset()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.partners.modal_edit.${name}`,
      )
    },
  },
})
</script>
