export default [
  {
    path: '/payments/trigger-sendings',
    name: 'admin-payment-trigger-sendings',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-trigger-sendings" */ '@admin/pages/admin/payment/TriggerSendings.vue'
      ),
  },
  {
    path: '/payments/trigger-sending-create',
    name: 'admin-payment-create-trigger-sending',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-trigger-sending" */ '@admin/pages/admin/payment/TheTriggerSending.vue'
      ),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/trigger-sendings/:triggerSendingId(\\d+)',
    name: 'admin-payment-the-trigger-sending',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-payment-the-trigger-sending" */ '@admin/pages/admin/payment/TheTriggerSending.vue'
      ),
  },
]
