var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex align-items-center mt-2 mb-4" }, [
    _c(
      "div",
      { staticClass: "w-100 d-flex flex-column" },
      [
        _c(
          "UiKitFormField",
          { staticClass: "mb-4", attrs: { label: _vm.t("avatar.label") } },
          [
            _c("UiKitUploadSmallImage", {
              on: {
                error: function ($event) {
                  _vm.processError(
                    `${_vm.t("avatar.error_message")}: ${$event}`
                  )
                },
              },
              model: {
                value: _vm.photo,
                callback: function ($$v) {
                  _vm.photo = $$v
                },
                expression: "photo",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          { staticClass: "mb-3", attrs: { label: _vm.t("alt_avatar.label") } },
          [
            _c("UiKitInput", {
              attrs: { placeholder: _vm.t("alt_avatar.placeholder") },
              model: {
                value: _vm.alt,
                callback: function ($$v) {
                  _vm.alt = $$v
                },
                expression: "alt",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          {
            staticClass: "mb-4",
            attrs: { label: _vm.t("full_name.label"), required: "" },
          },
          [
            _c("UiKitInput", {
              attrs: { placeholder: _vm.t("full_name.placeholder") },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          {
            staticClass: "mb-4",
            attrs: { label: _vm.t("position.label"), required: "" },
          },
          [
            _c("UiKitInput", {
              attrs: { placeholder: _vm.t("position.placeholder") },
              model: {
                value: _vm.position,
                callback: function ($$v) {
                  _vm.position = $$v
                },
                expression: "position",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "UiKitFormField",
          { attrs: { label: _vm.t("topic.label") } },
          [
            _c("UiKitInput", {
              attrs: { placeholder: _vm.t("topic.placeholder") },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }