import ApiApplication from 'ApiRest/Api/IncomeDiagnostics/Application'
import ApiApplications from 'ApiRest/Api/IncomeDiagnostics/Applications'
import ApiConfiguration from 'ApiRest/Api/IncomeDiagnostics/Configuration'
import ApiDiagnosticsCreateRetake from 'ApiRest/Api/IncomeDiagnostics/CreateRetake'
import { ApiDiagnosticsCtpTestList } from 'ApiRest/Api/IncomeDiagnostics/CtpList'
import { ApiDiagnosticsExtramural } from 'ApiRest/Api/IncomeDiagnostics/ExtramuralDiagnostics'
import ApiDiagnosticsFailedSubjects from 'ApiRest/Api/IncomeDiagnostics/FailedSubjects'
import { ApiDiagnosticsFullTime } from 'ApiRest/Api/IncomeDiagnostics/FullTimeDiagnostics'
import ApiSettingsSubjects from 'ApiRest/Api/IncomeDiagnostics/SettingsSubjects'
import ApiUploadTestingFile from 'ApiRest/Api/IncomeDiagnostics/UploadTestingFile'
import ApiOption from 'ApiRest/Api/Option'
import ApiSchoolList from 'ApiRest/Api/School/List'
import ApiTestingCheckTestList from 'ApiRest/Api/Test/List'
import ApiUserStudentAccountInactive from 'ApiRest/Api/User/Student/AccountInactive'
import Constants from 'Constants'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchApplications({ commit }, params) {
    const response = await ApiApplications.get(params)

    commit('setApplications', response.data)

    return response
  },

  async fetchApplication({ commit, state }) {
    const { data } = await ApiApplication.get(state.applicationId, {
      expand: [
        'status',
        'school',
        'grade',
        'client',
        'student',
        'invoice',
        'retakes',
        'passingSubjects',
        'additionalInvoice',
      ].join(','),
    })

    commit('setApplication', data)
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiSchoolList.get({
      hasModule: 'income-diagnostics',
    })

    commit('setSchools', data)
  },

  async fetchServices({ commit }, id) {
    const { data } = await ApiOption.getInvoiceServices(id)

    commit('setOptions', {
      key: 'services',
      data,
    })
  },

  async fetchFailedSubjects({ commit }, applicationId) {
    const { data } = await ApiDiagnosticsFailedSubjects.get(applicationId)

    commit('setOptions', {
      key: 'subjects',
      data,
    })
  },

  async createRetake({ commit }, { applicationId, payload }) {
    const { data } = await ApiDiagnosticsCreateRetake.post(
      applicationId,
      payload,
    )

    commit('addRetake', data)
  },

  async fetchConfiguration({ commit }, id) {
    const { data } = await ApiConfiguration.get(id)

    commit('setConfiguration', data)
  },

  async fetchGrades({ commit }, schoolId) {
    const { data } = await ApiOption.getGrades({
      schoolId,
    })

    commit('setOptions', {
      key: 'grades',
      data,
    })
  },

  async fetchSettingsSubjects({ commit }, schoolId) {
    const { data } = await ApiSettingsSubjects.get(schoolId)

    commit('setSettingsSubjects', data.gradeSubjectSettings)
  },

  async fetchManagers({ commit }, schoolId) {
    const { data } = await ApiOption.getCtpCheckers(schoolId)

    commit('setManagers', data)
  },

  async saveGradeSubjectSettings({ state, commit }) {
    const { data } = await ApiSettingsSubjects.post(state.schoolId, {
      gradeSubjectSettings: state.gradeSubjectSettings,
    })

    commit('setSettingsSubjects', data.gradeSubjectSettings)
  },

  uploadFilesPassingSubject({ state }, { files, testingId }) {
    return ApiUploadTestingFile.post(state.applicationId, {
      testing: {
        id: testingId,
      },
      files,
    })
  },

  async fetchExtramuralDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsExtramural.get(
      state.schoolId,
      state.grade.id,
    )

    commit('setSubjectsExtramuralDiagnostics', data)
  },

  async saveExtramuralDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsExtramural.post(
      state.schoolId,
      state.grade.id,
      {
        subjectSettings: state.subjectsExtramuralDiagnostics.subjectSettings,
      },
    )

    commit('setSubjectsExtramuralDiagnostics', data)
  },

  async fetchFullTimeDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsFullTime.get(
      state.schoolId,
      state.grade.id,
    )

    commit('setSubjectsFullTimeDiagnostics', data)
  },

  async fetchTestsList({ commit, state }) {
    const { data } = await ApiTestingCheckTestList.get({
      school: {
        id: state.schoolId,
      },

      type: {
        id: Constants.testingTypes.TEST,
      },
    })

    commit('setTests', data)
  },

  async fetchSubjects({ commit }) {
    const { data } = await ApiOption.getSubjects()

    commit('setSubjects', data)
  },

  async saveFullTimeDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsFullTime.post(
      state.schoolId,
      state.grade.id,
      {
        subjectSettings: state.subjectsFullTimeDiagnostics.subjectSettings,
      },
    )

    commit('setSubjectsFullTimeDiagnostics', data)
  },

  async fetchCheckers({ state }, subjectId) {
    const { data } = await ApiOption.getCtpCheckers(state.schoolId, {
      subject: {
        id: subjectId,
      },

      grade: {
        id: state.grade.id,
      },
    })

    return data
  },

  async fetchTestsFromCtp({ state }, { subjectId, gradeId }) {
    const { data } = await ApiDiagnosticsCtpTestList.get({
      school: {
        id: state.schoolId,
      },

      grade: {
        id: gradeId,
      },

      subject: {
        id: subjectId,
      },
    })

    return data
  },

  async blockStudent({ commit, state }) {
    const { data } = await ApiUserStudentAccountInactive.put(
      state.application.student.id,
    )

    commit('setStudentAccountStatus', data?.accountStatus)
  },
}
