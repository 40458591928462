// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileUploadMany__imageActions__CE00 {\n  position: absolute;\n  right: 20px;\n  bottom: 20px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 8px;\n  -webkit-column-gap: 8px;\n     -moz-column-gap: 8px;\n          column-gap: 8px;\n}\n.FileUploadMany__imageAction__\\+vKl {\n  padding-right: 0 !important;\n  padding-left: 0 !important;\n}\n.FileUploadMany__imageAction__\\+vKl {\n  width: 40px;\n}", "",{"version":3,"sources":["webpack://./webpack/components/FileUploadMany.vue"],"names":[],"mappings":"AACA;EAGE,kBAAA;EACA,WAHS;EAIT,YAJS;EAKT,aAAA;EACA,8BAAA;EACA,oBAAA;EAAA,uBAAA;KAAA,oBAAA;UAAA,eAAA;AAFF;AAKA;EAEE,2BAAA;EACA,0BAAA;AAFF;AADA;EACE,WAAA;AAAF","sourcesContent":["\n.imageActions {\n  $gutter: 20px;\n\n  position: absolute;\n  right: $gutter;\n  bottom: $gutter;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 8px;\n}\n\n.imageAction {\n  width: 40px;\n  padding-right: 0 !important;\n  padding-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageActions": "FileUploadMany__imageActions__CE00",
	"imageAction": "FileUploadMany__imageAction__+vKl"
};
export default ___CSS_LOADER_EXPORT___;
