export default () => ({
  triggerSendings: [],

  triggerSending: {
    id: null,
    name: '',
    subject: '',
    mailBody: '',
    notificationBody: '',
    sendingDelay: null,
    triggerTypes: [],
    status: null,
    promocodeGroup: null,
  },

  options: {
    triggerTypes: [],
    triggerStatuses: [],
    promocodeGroups: [],
  },

  pagination: null,
  filters: null,
})
