var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "UiKitFormField",
        { staticClass: "mb-2", attrs: { label: _vm.t("label") } },
        [
          _c("UiKitWysiwyg", {
            ref: "wysiwyg",
            attrs: {
              placeholder: _vm.t("placeholder"),
              "keep-styles": false,
              plugins: _vm.plugins,
              setup: _vm.setup,
              toolbar: _vm.toolbar,
            },
            on: { init: _vm.onInit, input: _vm.emitChange },
            model: {
              value: _vm.localItem.text.body,
              callback: function ($$v) {
                _vm.$set(_vm.localItem.text, "body", $$v)
              },
              expression: "localItem.text.body",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("UiKitCheckbox", {
        attrs: { label: _vm.t("transparent_background") },
        on: { change: _vm.emitChange },
        model: {
          value: _vm.localItem.text.isBackgroundTransparent,
          callback: function ($$v) {
            _vm.$set(_vm.localItem.text, "isBackgroundTransparent", $$v)
          },
          expression: "localItem.text.isBackgroundTransparent",
        },
      }),
      _vm._v(" "),
      _c("ModalAddElements", {
        ref: "ModalAddElements",
        on: { add: _vm.onAddElements },
      }),
      _vm._v(" "),
      _c("ModalAddTextsWithTitle", {
        ref: "ModalAddTextsWithTitle",
        on: { add: _vm.onAddTextsWithTitle },
      }),
      _vm._v(" "),
      _c("ModalAddIcons", {
        ref: "ModalAddIcons",
        on: { add: _vm.onAddIcons },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }