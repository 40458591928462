// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PartnersItem__wrapper__\\+A8s {\n  display: flex;\n  width: 80px;\n  height: 80px;\n  overflow: hidden;\n  border-radius: 5px;\n  align-items: center;\n  justify-content: center;\n}\n.PartnersItem__wrapper__\\+A8s img {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./webpack/@admin/components/admin/productManagement/product/content/items/partners/PartnersItem.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACE,WAAA;AAAJ","sourcesContent":["\n.wrapper {\n  display: flex;\n  width: 80px;\n  height: 80px;\n  overflow: hidden;\n  border-radius: 5px;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PartnersItem__wrapper__+A8s"
};
export default ___CSS_LOADER_EXPORT___;
