var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _vm.$style.imageEditorContainer },
    [
      _c("TuiImageEditor", {
        ref: "imageEditor",
        class: _vm.$style.imageEditor,
        attrs: { options: _vm.options },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }