import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnosticsAcceptanceAct = new (class extends ApiBase {
  /**
   * Получения акта сдачи-приемки для школы
   *
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/school/${schoolId}/template/diagnostics/acceptance-act`

    return this._GET(url)
  }

  /**
   * Загрузка оферты акта сдачи-приемки
   *
   * @param {number} schoolId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, data) {
    const url = `/school/${schoolId}/template/diagnostics/acceptance-act/upload`

    return this._POST(url, data)
  }
})()
